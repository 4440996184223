<template>
  <v-container
    fluid
    fill-height
    :class="$style.auth"
    class="d-flex justify-start align-center flex-column pa-0"
  >
    <v-overlay :value="processing">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div>
      <v-row dense>
        <v-col cols="12" class="text-center">
          <router-link :to="{ name: 'mypage-index' }">
            <img :src="require('@/assets/logo.svg')" width="180" height="88" />
          </router-link>
        </v-col>
      </v-row>
      <v-row dense class="mt-1 mb-0">
        <v-col cols="12" class="text-center">
          <h2 :class="$style.title">{{ title }}</h2>
        </v-col>
      </v-row>
      <v-row dense class="mb-4 mt-0">
        <v-col cols="12" class="text-center">
          <h3 :class="$style.title">{{ titleEn }}</h3>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col
          cols="12"
          class="d-flex justify-center align-center"
          v-if="Object.keys(errorMessages).length"
        >
          <v-card
            flat
            width="300"
            background="transparent"
            :style="{
              backgroundColor: 'transparent'
            }"
          >
            <error-list :errorMessages="errorMessages" :names="['message']" />
          </v-card>
        </v-col>
        <v-col cols="12" class="d-flex justify-center">
          <v-card
            color="#fff"
            class="py-1 pa-5 mb-3 rounded-xl"
            rounded
            :class="$style.form"
          >
            <v-row dense>
              <v-col cols="12">
                <MyForm
                  :formKey="formKey"
                  :entity="entity"
                  :input="input"
                  ref="form"
                >
                  <v-container class="pa-0">
                    <v-col cols="12">
                      <ValidationProvider
                        v-slot="{ errors }"
                        :rules="{
                          required: 'required',
                          numeric: 'numeric',
                          phoneNumberCheck: []
                        }"
                        name="新しい電話番号"
                        vid="newPhoneNumber"
                        ref="v-newPhoneNumber"
                      >
                        <v-text-field
                          v-model="input['newPhoneNumber']"
                          name="newPhoneNumber"
                          label="新しい電話番号 New Phone Number"
                          placeholder="例) 09012345678"
                          clearable
                          autocomplete="off"
                          :error-messages="errors[0]"
                          background-color="#fff"
                          ref="newPhoneNumberForm"
                        >
                        </v-text-field>
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="phoneNumber"
                        name="phoneNumber"
                        label="現在の電話番号 Current Phone Number"
                        clearable
                        autocomplete="off"
                        background-color="#fff"
                        disabled
                      >
                      </v-text-field>
                    </v-col>
                  </v-container>
                </MyForm>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex justify-center">
                <base-btn
                  :disabled="btnDisabled"
                  label="認証コード送信 Authentication Code"
                  @click="onConfirm"
                  :style="{
                    color: 'white',
                    fontSize: '1rem',
                    boxShadow: 'none'
                  }"
                />
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col cols="6">
          <v-btn
            class="text-center"
            exact
            text
            block
            color="#5993ac"
            :class="$style.back"
            :to="{ name: 'account-index' }"
          >
            <v-icon :class="$style.back_icon">mdi-chevron-left</v-icon>戻る (Back)
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import MyForm from "@/entries/front/components/form";
import ErrorList from "@/entries/front/components/error/error-list";

export default {
  components: {
    MyForm,
    ErrorList
  },

  data() {
    return {
      resourceName: "accounts",
      formKey: "account-name",
      title: "電話番号変更",
      titleEn: "Change Phone Number",
      input: {
        newPhoneNumber: ""
      },
      valid: {
        newPhoneNumber: false
      }
    };
  },
  watch: {
    "input.newPhoneNumber": {
      handler(value, old) {
        if (value !== old) {
          this.validate("newPhoneNumber");
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      phoneNumber: "accounts/entity/phoneNumber"
    }),
    isError() {
      return this.$store.getters[`${this.resourceName}/entity/isError`];
    },
    processing() {
      return this.$store.getters[`${this.resourceName}/entity/processing`];
    },
    entity() {
      return this.$store.getters[`${this.resourceName}/entity/entity`];
    },
    errorMessages() {
      return this.$store.getters[`${this.resourceName}/entity/errorMessages`];
    },
    btnDisabled() {
      const { newPhoneNumber } = this.valid;
      return !newPhoneNumber;
    }
  },
  methods: {
    async validate(refName) {
      const providor = this.$refs[`v-${refName}`];
      const { valid } = await providor.validate();
      this.$nextTick(() => {
        this.valid[refName] = valid;
      });
    },
    popstate() {
      window.history.pushState(null, null, null);
      return;
    },
    onConfirm() {
      this.$store
        .dispatch(`${this.resourceName}/entity/issueConfirmationCode`, {
          newPhoneNumber: this.input["newPhoneNumber"]
        })
        .then(() => {
          this.$store.commit("form/clearDirty");
          this.$router.push({ name: "account-auth-code-phone" });
        })
        .catch(() => {});
    }
  },
  created() {
    // アカウント情報が存在しない場合は、アカウント情報画面に強制的に遷移
    if (!Object.keys(this.entity).length) {
      this.$router.push({ name: "account-index" });
    } else {
      this.input.newPhoneNumber = this.entity.newPhoneNumberInfo.newPhoneNumber;
    }
  },
  mounted() {
    window.addEventListener("popstate", this.popstate);
    this.popstate();

    // iOSの数値キーボードを表示させるためにinputに属性を設定
    const targetElement = this.$refs.newPhoneNumberForm.$el.getElementsByTagName("input")[0];
    targetElement.setAttribute("type", "tel");
    targetElement.setAttribute("inputmode", "numeric");
  },
  beforeDestroy() {
    window.removeEventListener("popstate", this.popstate);
    this.$store.commit("form/changeDirty", [
      {
        key: this.formKey,
        isDirty: !!this.newPhoneNumber
      }
    ]);
  }
};
</script>

<style module lang="scss">
@import "@/styles/common.scss";
.title {
  font-size: 16px;
  color: #6f808c;
  font-weight: 600;
}

.form {
  background-color: #ffffff;
  width: 100%;
  min-width: 320px;
  max-width: 320px;
  padding: 15px 30px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05) !important;
}

.error {
  color: #ff1100;
  text-align: center;
  font-size: 12px;
}

.back {
  font-size: 13px !important;
  font-family: $body-font-family;
}

.back_icon {
  margin-top: 2px;
}

.reissue-link {
  font-size: 16px;
  text-decoration-line: none;
  color: $base-font-color !important;
}
</style>
