<template>
  <ul class="ma-0 pa-0 pl-1" :class="$style['error-list']">
    <li
      :class="$style['error-item']"
      class="pa-1"
      v-for="(message, index) in messages(names)"
      :key="index"
    >
      {{ message }}
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    errorMessages: {
      default: () => {}
    },
    names: {
      default: () => []
    }
  },
  computed: {
    messages() {
      return (names) => {
        let messages = [];
        if (names.length === 0) {
          Object.keys(this.errorMessages).forEach((pName) => {
            let msg = this.errorMessages[pName];
            if (!Array.isArray(msg)) {
              msg = [this.errorMessages[pName]];
            }
            messages.push(msg.join(""));
          });
        } else {
          for (let name of names) {
            let tName = Object.keys(this.errorMessages).find(
              (pName) => pName === name
            );
            let msg = this.errorMessages[tName];
            if (!Array.isArray(msg)) {
              msg = [this.errorMessages[tName]];
            }
            messages.push(msg.join(""));
          }
        }
        return messages;
      };
    }
  }
};
</script>

<style module lang="scss">
.error-list {
  min-height: 32px !important;
}
.error-item {
  list-style-type: none;
  text-align: left;
  color: #ff1100;
  font-size: 12px;
  &:not(:first-child) {
    margin-top: 2px !important;
  }
}
</style>
