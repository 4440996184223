var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"d-flex justify-start align-center flex-column pa-0",class:_vm.$style.auth,attrs:{"fluid":"","fill-height":""}},[_c('v-overlay',{attrs:{"value":_vm.processing}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('div',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('router-link',{attrs:{"to":{ name: 'mypage-index' }}},[_c('img',{attrs:{"src":require('@/assets/logo.svg'),"width":"180","height":"88"}})])],1)],1),_c('v-row',{staticClass:"mt-1 mb-0",attrs:{"dense":""}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('h2',{class:_vm.$style.title},[_vm._v(_vm._s(_vm.title))])])],1),_c('v-row',{staticClass:"mb-4 mt-0",attrs:{"dense":""}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('h3',{class:_vm.$style.title},[_vm._v(_vm._s(_vm.titleEn))])])],1),_c('v-row',{attrs:{"dense":""}},[(Object.keys(_vm.errorMessages).length)?_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12"}},[_c('v-card',{style:({
            backgroundColor: 'transparent'
          }),attrs:{"flat":"","width":"300","background":"transparent"}},[_c('error-list',{attrs:{"errorMessages":_vm.errorMessages,"names":['message']}})],1)],1):_vm._e(),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"py-1 pa-5 mb-3 rounded-xl",class:_vm.$style.form,attrs:{"color":"#fff","rounded":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('MyForm',{ref:"form",attrs:{"formKey":_vm.formKey,"entity":_vm.entity,"input":_vm.input}},[_c('v-container',{staticClass:"pa-0"},[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{ref:"v-newPhoneNumber",attrs:{"rules":{
                        required: 'required',
                        numeric: 'numeric',
                        phoneNumberCheck: []
                      },"name":"新しい電話番号","vid":"newPhoneNumber"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('v-text-field',{ref:"newPhoneNumberForm",attrs:{"name":"newPhoneNumber","label":"新しい電話番号 New Phone Number","placeholder":"例) 09012345678","clearable":"","autocomplete":"off","error-messages":errors[0],"background-color":"#fff"},model:{value:(_vm.input['newPhoneNumber']),callback:function ($$v) {_vm.$set(_vm.input, 'newPhoneNumber', $$v)},expression:"input['newPhoneNumber']"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"name":"phoneNumber","label":"現在の電話番号 Current Phone Number","clearable":"","autocomplete":"off","background-color":"#fff","disabled":""},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}})],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center"},[_c('base-btn',{style:({
                  color: 'white',
                  fontSize: '1rem',
                  boxShadow: 'none'
                }),attrs:{"disabled":_vm.btnDisabled,"label":"認証コード送信 Authentication Code"},on:{"click":_vm.onConfirm}})],1)],1)],1)],1)],1),_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"text-center",class:_vm.$style.back,attrs:{"exact":"","text":"","block":"","color":"#5993ac","to":{ name: 'account-index' }}},[_c('v-icon',{class:_vm.$style.back_icon},[_vm._v("mdi-chevron-left")]),_vm._v("戻る (Back) ")],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }